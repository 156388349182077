// extracted by mini-css-extract-plugin
export var action = "integration-module--action--4b4df";
export var actions = "integration-module--actions--bf296";
export var dots = "integration-module--dots--eed49";
export var emailGraphic = "integration-module--email-graphic--96f4c";
export var emailIcon = "integration-module--email-icon--2270d";
export var feature = "integration-module--feature--ffde6";
export var integration = "integration-module--integration--ba24a";
export var logo = "integration-module--logo--1401e";
export var logoContainer = "integration-module--logo-container--33bc1";
export var logoIcon = "integration-module--logo-icon--4f381";
export var logos = "integration-module--logos--dc21f";
export var nav = "integration-module--nav--7f223";
export var separator = "integration-module--separator--0a148";